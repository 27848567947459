.App {
    height: 100vh;
    /* width: 1280px; */
    width: 100%;
    /* min-height: 100%; */
    image-rendering: pixelated;
    overflow-y: hidden;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

div {
    display: flex;
}

#root {
    height: 100%;
}

.white {
    color: #fff;
}


.blinking-cursor {
    background-color: #fff;
    width: 0.8em;
    height: 0.15em;
    -webkit-animation: 0.65s blink step-end infinite;
    -moz-animation: 0.65s blink step-end infinite;
    -ms-animation: 0.65s blink step-end infinite;
    -o-animation: 0.65s blink step-end infinite;
    animation: 0.65s blink step-end infinite;
}

@keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
}

@-moz-keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
}

@-webkit-keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
}

@-ms-keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
}

@-o-keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
}